<section class="oneCreation-main">
  <div class="oneCreation-main__body">
    <section class="oneCreation-main__content oneCreation-main__content--simple">
      <router-outlet name="navbar"></router-outlet>
      <app-alert></app-alert>
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </section>
  </div>
</section>
