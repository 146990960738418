import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';

import { faBell, faCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subscription, zip } from 'rxjs';

import { IUser } from '@core/models/user.model';
import { NavigationService } from '@core/services/navigation.service';
import { isDesktopUp } from '@shared/helpers/helpers';
import { ICustomFormOption } from '@shared/features/custom-forms/custom-form-option.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Output() notificationsOpened = new EventEmitter<boolean>();

  faBell = faBell;
  faCheck = faCheck;
  faQuestionCircle = faQuestionCircle;

  account$: Observable<IUser>;
  userOrganizationName: string;
  selectedUserOrganization: ICustomFormOption<string>;
  menuNabStatus$: Observable<boolean>;
  isPlatformUser$: Observable<boolean>;
  notificationsNumber$: Observable<{ [key: string]: number }>;
  userOrganizations: ICustomFormOption<string>[];
  subscriptions = new Subscription();
  tabletDown = false;

  constructor(private navigationService: NavigationService) {}

  ngOnInit(): void {
    this.setObservables();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleMediaChange = (media: string) => (this.tabletDown = !isDesktopUp(media));

  openMyAccount = (): void => this.navigationService.openMyAccount();

  openNotifications = (): void => this.navigationService.openNotifications();

  setObservables = (): void => {
    this.account$ = this.navigationService.userProfile$;
    this.isPlatformUser$ = this.navigationService.isPlatformUser$;
    this.notificationsNumber$ = this.navigationService.requestsCount$;
    this.subscriptions.add(this.navigationService.mediaChange$.subscribe(this.handleMediaChange));
    this.setOrganizationData();
  };

  setOrganizationData = (): void => {
    this.subscriptions.add(
      zip(this.navigationService.userOrganization$, this.navigationService.userOrganizations$).subscribe(response => {
        const [userOrganization, userOrganizations] = response;
        this.userOrganizations = userOrganizations;
        this.selectedUserOrganization = userOrganizations?.find(userOrg => {
          return userOrg.value === userOrganization;
        });
      })
    );
  };

  setUserOrganization = (organizationId: string): void => {
    this.subscriptions.add(
      this.navigationService.setUserOrganization(organizationId).subscribe(() => {
        this.setOrganizationData();
      })
    );
  };
}
