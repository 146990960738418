import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AlertService } from '@core/alert/alert.service';
import { IException } from '@core/models/exception.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private alertService: AlertService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(environment.avoidErrorHandler)) {
      const newRequest = request.clone({
        headers: request.headers.delete(environment.avoidErrorHandler),
      });
      return next.handle(newRequest);
    }
    return next.handle(request).pipe(
      catchError((httpError: HttpErrorResponse) => {
        if (request.responseType === 'blob' && httpError.error instanceof Blob) {
          return from(
            Promise.resolve(httpError).then(async (x: HttpErrorResponse) => {
              const errorMsg = JSON.parse(await x.error.text());
              const response: IException = {
                status: errorMsg.status || 500,
                message: errorMsg.message,
                module: errorMsg.module,
                code: errorMsg.code,
                time: x.url ?? undefined,
              };
              this.setErrorResponse(response);
              throw response;
            })
          );
        } else {
          this.setErrorResponse(httpError.error || httpError);
          return throwError(httpError);
        }
      })
    );
  }

  setErrorResponse = (httpError: IException | string): void => {
    let errorMessage = '';
    let _httpError;
    try {
      _httpError = JSON.parse(httpError as string);
    } catch (err) {
      _httpError = httpError;
    }
    const { code, message, status, error, path } = _httpError;
    const headline = status && (code || error) ? `<strong>${status} - ${code || error}</strong><br/>` : '';
    const reason = message || path ? `<p><br/>${message || path}</p>` : '';
    errorMessage = headline + reason;
    this.alertService.error(errorMessage);
  };
}
