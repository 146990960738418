import { INotification } from '@core/models/notification.model';

export enum ActionType {
  APPROVE = 'Approve',
  CONTRIBUTE = 'Contribute',
  CLOSE = 'Close',
  GOTOPOLICY = 'Go to Policy',
  IGNORE = 'Ignore',
  REJECT = 'Reject',
  REVIEW = 'Review',
  UNSUBSCRIBE = 'Unsubscribe',
}

export interface INotificationAction {
  actionType: ActionType;
  notification: INotification;
}
