import { Component, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';

import { INotification } from '@core/models/notification.model';
import { INotificationMessage } from '@core/models/notification-message.model';
import { ActionType, INotificationAction } from './../notification-action.model';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent {
  @Input() notification: INotification;
  @Input() notificationParams: INotificationMessage;
  @Output() optionClicked = new EventEmitter<INotificationAction>();
  @Output() linkClicked = new EventEmitter<INotification>();

  goto = (event: PointerEvent): void => {
    if (event.target && (event.target as HTMLElement)?.nodeName === 'STRONG') {
      this.linkClicked.emit(this.notification);
    }
  };

  triggerAction = (actionType: ActionType): void => {
    this.optionClicked.emit({ actionType, notification: this.notification });
  };
}
