import { Observable } from 'rxjs/internal/Observable';
import { Component, OnInit } from '@angular/core';

import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  loading$: Observable<boolean>;
  label$: Observable<string>;

  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loading$ = this.loaderService.isLoading$;
    this.label$ = this.loaderService.label$;
  }
}
