<section class="nav-menu" #main>
  <ul>
    <li [ngClass]="{ 'nav-menu__item--disabled': isPlatform$ | async }">
      <a [routerLink]="['/dashboard']" [routerLinkActive]="'active'">
        <fa-icon [icon]="faHome"></fa-icon>
        {{ 'Home' | translate }}
      </a>
    </li>

    <li [ngClass]="{ 'nav-menu__item--disabled': isPlatform$ | async }">
      <a [routerLink]="['/subscriptions']" [routerLinkActive]="'active'">
        <fa-icon [icon]="faChartSimple"></fa-icon>
        {{ 'Analytics' | translate }}
      </a>
    </li>

    <li [ngClass]="{ 'nav-menu__item--disabled': isPlatform$ | async }">
      <a [routerLink]="['/policies/policy-viewer']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
        <span class="oc-icon oc-icon--eye"></span>
        {{ 'Decrypt File' | translate }}
      </a>
    </li>

    <ng-container *ngIf="isAdmin">
      <li>
        <button type="button" (click)="collapse.toggle()">
          <fa-icon [icon]="faUsersCog"></fa-icon>
          {{ 'Admin' | translate }}
          <fa-icon class="nav-menu__colapse" [icon]="isCollapsed ? faCaretDown : faCaretUp"></fa-icon>
        </button>

        <ul #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
          <li>
            <a [routerLink]="['/admin/entities']" [routerLinkActive]="'active'">{{ 'Entities' | translate }}</a>
          </li>
          <li>
            <a [routerLink]="['/admin/users']" [routerLinkActive]="'active'">{{ 'Users' | translate }}</a>
          </li>
        </ul>
      </li>
    </ng-container>

    <li>
      <button (click)="openMyAccount()">
        <img class="nav-menu__user" [src]="(account$ | async)?.picture" [alt]="'One Creation' + (account$ | async)?.nickname" />
        {{ (account$ | async)?.nickname }}
      </button>
    </li>

    <li>
      <a [routerLink]="['/help']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
        <fa-icon [icon]="faQuestionCircle"></fa-icon>
        {{ 'Help & Support' | translate }}
      </a>
    </li>
    <li>
      <a (click)="logout()">
        <fa-icon [icon]="faPowerOff"></fa-icon>
        {{ 'Logout' | translate }}
      </a>
    </li>
  </ul>
</section>
