import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { AuthService } from '@core/auth/auth.service';
import { IUserAccount } from '@core/models/user-account.model';
import { NavigationService } from '@core/services/navigation.service';
import { MyAccountComponent } from '@shared/components/modals/my-account/my-account.component';
import { UserService } from '@shared/services/user.service';
import { environment } from 'src/environments/environment';

@Component({ template: '' })
export class BaseLayoutComponent implements OnInit {
  encryptionKey: string;
  modalRef: NgbModalRef;
  subscriptions = new Subscription();
  user: IUserAccount = null;

  constructor(
    protected authService: AuthService,
    protected modalService: NgbModal,
    protected navigationService: NavigationService,
    protected router: Router,
    protected userService: UserService,
    protected window: Window
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getEncryptionKey();
    this.getAccountModalStatus();
    this.reloadOnOrganizationChange();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getAccountModalStatus = (): void => {
    this.subscriptions.add(this.navigationService.accountClicked$.subscribe(this.openMyAccount));
  };

  getEncryptionKey = (): void => {
    this.subscriptions.add(this.userService.getEncryptionKey().subscribe(key => (this.encryptionKey = key)));
  };

  getUser = (): void => {
    this.subscriptions.add(this.userService.getUser().subscribe(account => (this.user = account)));
  };

  logout = (): void => {
    const { AUTHCONF } = environment;
    this.authService.logout({ client_id: AUTHCONF.clientId, returnTo: AUTHCONF.returnTo });
  };

  openMyAccount = (): void => {
    this.modalRef = this.modalService.open(MyAccountComponent, { size: 'lg' });
    this.modalRef.componentInstance.user = this.user;
    this.modalRef.componentInstance.encryptionKey = this.encryptionKey;
  };

  reloadOnOrganizationChange = (): void => {
    this.subscriptions.add(
      this.userService.userOrganizationChange$.subscribe(() => {
        this.router.navigate(['/']).then(() => {
          this.window.location.reload();
        });
      })
    );
  };
}
