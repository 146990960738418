import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { MatchmediaService } from '@core/services/matchmedia.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'oneCreation-front';

  constructor(private matchMedia: MatchmediaService, private translate: TranslateService) {
    this.translate.setDefaultLang('en');
    this.matchMedia.init();
  }
}
