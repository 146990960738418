<nav class="navbar">
  <ng-container *ngIf="!tabletDown; else tabletDownTemplate">
    <section class="items-right-side">
      <div class="icons">
        <a class="icons__item" (click)="openNotifications()">
          <fa-icon [icon]="faBell"></fa-icon>
          <span
            class="badge badge-notify"
            *ngIf="(notificationsNumber$ | async)?.total as total"
            [ngClass]="{ 'badge-notify--pendingRequest': (notificationsNumber$ | async)?.pendingRequests > 0 }"
          >
            {{ total }}
          </span>
        </a>
      </div>
      <a class="user" (click)="openMyAccount()">
        <div class="user__img" [style.background-image]="'url(' + (account$ | async)?.picture + ')'"></div>
      </a>
      <ng-container *ngIf="!(isPlatformUser$ | async)">
        <ng-container *ngIf="selectedUserOrganization && userOrganizations && userOrganizations.length > 1">
          <div class="navbar__organization-toogle" ngbDropdown [placement]="'bottom-end'">
            <button type="button" ngbDropdownToggle>{{ selectedUserOrganization.label }}</button>
            <div ngbDropdownMenu>
              <ng-container *ngFor="let organization of userOrganizations">
                <button
                  ngbDropdownItem
                  [ngClass]="{ 'dropdown-item--selected': selectedUserOrganization.value === organization.value }"
                  (click)="setUserOrganization(organization.value)"
                >
                  {{ organization.label }}

                  <fa-icon [icon]="faCheck"></fa-icon>
                </button>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </section>
  </ng-container>
  <ng-template #tabletDownTemplate>
    <div class="icons__item" (click)="openNotifications()">
      <fa-icon [icon]="faBell"></fa-icon>
      <span
        class="badge badge-notify"
        *ngIf="(notificationsNumber$ | async)?.total as total"
        [ngClass]="{ 'badge-notify--pendingRequest': (notificationsNumber$ | async)?.pendingRequests > 0 }"
      >
        {{ total }}
      </span>
    </div>
  </ng-template>
</nav>
