import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import {
  faHome,
  faUsers,
  faChartSimple,
  faQuestionCircle,
  faFileInvoiceDollar,
  faList,
  faPlus,
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';

import { AuthService } from '@core/auth/auth.service';
import { Role } from '@core/models';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  faQuestionCircle = faQuestionCircle;
  faHome = faHome;
  faFileInvoiceDollar = faFileInvoiceDollar;
  faUsers = faUsers;
  faCog = faCog;
  faChartSimple = faChartSimple;
  faList = faList;
  faPlus = faPlus;

  roles: Role[];
  isPlatform$: Observable<boolean>;

  constructor(protected authService: AuthService, protected userService: UserService) {
    this.roles = authService.userRoles();
  }

  ngOnInit(): void {
    this.isPlatform$ = this.userService.isPlatformUser();
  }

  get isAdmin(): boolean {
    return this.authService.userRoles().includes('ADMIN');
  }

  get isNonViewer(): boolean {
    return this.authService.userRoles().includes('EDITOR') || this.authService.userRoles().includes('REVIEWER');
  }
}
