<div class="notification" [@openClose]="(opened$ | async) ? true : false">
  <div class="notification__header">
    <div class="notification__header__title" (click)="listMyRequests()" [ngStyle]="{'font-weight': isMyRequestsSelected ? 'bold':'normal'}">
      {{'Notifications' | translate}} ( {{myRequests?.length || 0}} )
    </div>
    <div
      class="notification__header__title"
      (click)="listMyPendingRequests()"
      [ngStyle]="{'font-weight': !isMyRequestsSelected ? 'bold':'normal'}"
    >
      {{'My Pending Requests' | translate}} ( {{myPendingRequests.length || 0}} )
    </div>
  </div>
  <div class="notification__content">
    <div class="notification__clear-all" *ngIf="requestNotifications?.length>0 && isMyRequestsSelected">
      <button class="a-link" (click)="clearAll()">{{'Clear All' | translate}}</button>
    </div>
    <ng-container *ngFor="let request of isMyRequestsSelected ? requestNotifications : pendingRequestNotifications">
      <app-notification
        [notificationParams]="request.notificationMessage"
        [notification]="request.notification"
        (linkClicked)="goToResource($event)"
        (optionClicked)="triggerAction($event)"
      ></app-notification>
    </ng-container>
  </div>
</div>
