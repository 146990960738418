import { AfterViewInit, Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';

import { faCaretDown, faCaretUp, faPowerOff, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';

import { AuthService } from '@core/auth/auth.service';
import { IUser } from '@core/models/user.model';
import { SidebarComponent } from '@core/layouts/sidebar/sidebar.component';
import { NavigationService } from '@core/services/navigation.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent extends SidebarComponent implements OnInit, AfterViewInit {
  @ViewChild('main') main: ElementRef;

  faQuestionCircle = faQuestionCircle;
  faPowerOff = faPowerOff;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  account$: Observable<IUser>;
  isCollapsed = true;

  constructor(
    protected authService: AuthService,
    protected navigationService: NavigationService,
    private renderer: Renderer2,
    protected userService: UserService,
    private window: Window
  ) {
    super(authService, userService);
  }

  ngOnInit(): void {
    this.account$ = this.authService.userProfile$;
    this.isPlatform$ = this.userService.isPlatformUser();
  }

  ngAfterViewInit(): void {
    this.window.setTimeout(() => {
      this.renderer.addClass(this.main.nativeElement, 'nav-menu--visible');
    }, 100);
  }

  logout = (): void => this.navigationService.logout();

  openMyAccount = (): void => this.navigationService.toggleAccountModal();
}
