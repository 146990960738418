import { ActivatedRouteSnapshot, DetachedRouteHandle, BaseRouteReuseStrategy } from '@angular/router';

type DetachedRouteHandleObject = { [key: string]: DetachedRouteHandle };

export class CustomRouteReuseStrategy implements BaseRouteReuseStrategy {
  handlers: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.shouldReuse || false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandleObject): void {
    if (route.data.shouldReuse) {
      this.handlers[route.routeConfig.path] = handle;
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig && !!this.handlers[route.routeConfig.path];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandleObject {
    if (!route.routeConfig) return null;
    return this.handlers[route.routeConfig.path];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  shouldReuseRoute(future: ActivatedRouteSnapshot, _curr: ActivatedRouteSnapshot): boolean {
    return future.data.shouldReuse || false;
  }
}
