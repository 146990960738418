<article class="notification-item">
  <div class="notification-item__header">
    <p class="title">{{ notificationParams.title }}</p>
    <p class="time">{{ notificationParams.date }}</p>
  </div>
  <div class="notification-item__body">
    <div [innerHtml]="notificationParams.message" (click)="goto($event)"></div>
  </div>
  <ng-container *ngIf="notificationParams.options">
    <div class="notification-item__options">
      <button
        *ngFor="let option of notificationParams.options"
        class="app-btn {{ option === 'Close' ? 'app-btn--secondary' : 'app-btn--primary' }}"
        (click)="triggerAction(option)"
      >
        {{ option }}
      </button>
    </div>
  </ng-container>
</article>
