<section class="oneCreation-main">
  <app-notification-center class="notification__section"></app-notification-center>

  <div class="oneCreation-main__body">
    <section class="oneCreation-main__sidenav">
      <router-outlet name="sidebar"></router-outlet>
    </section>
    <section class="oneCreation-main__content">
      <router-outlet name="navbar"></router-outlet>
      <app-alert></app-alert>
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </section>
  </div>
</section>
