import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';

import { AuthGuard } from '@core/auth/auth.guard';
import { PlatformAdminGuard } from '@core/guards/platform-admin.guard';
import { SelectedOrganizationGuard } from '@core/guards/selected-organization.guard';
import { NAVBAR_ROUTE } from '@core/layouts/navbar/navbar.route';
import { SIDEBAR_ROUTE } from '@core/layouts/sidebar/sidebar.route';
import { CustomRouteReuseStrategy } from './CustomRouteReuseStrategy';
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { SimpleLayoutComponent } from './layouts/simple-layout.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    data: { shouldReuse: true },
    canActivate: [AuthGuard, SelectedOrganizationGuard],
    children: [
      NAVBAR_ROUTE,
      SIDEBAR_ROUTE,
      {
        path: '',
        canActivate: [PlatformAdminGuard],
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: 'oc-viewer',
        canActivate: [PlatformAdminGuard],
        loadChildren: () => import('./standalone-viewer/standalone-viewer.module').then(m => m.StandaloneViewerModule),
      },
      {
        path: 'oc-viewer/:policyId',
        canActivate: [PlatformAdminGuard],
        loadChildren: () => import('./standalone-viewer/standalone-viewer.module').then(m => m.StandaloneViewerModule),
      },
      {
        path: 'oc-viewer/:policyId/:contributionId',
        canActivate: [PlatformAdminGuard],
        loadChildren: () => import('./standalone-viewer/standalone-viewer.module').then(m => m.StandaloneViewerModule),
      },
      {
        path: 'consent-broker',
        canActivate: [PlatformAdminGuard],
        loadChildren: () => import('./consent-broker/consent-broker.module').then(m => m.ConsentBrokerModule),
      },
      {
        path: 'help',
        loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
      },
      {
        path: 'subscriptions',
        canActivate: [PlatformAdminGuard],
        loadChildren: () => import('./subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
      },
      {
        path: 'datasets',
        canActivate: [PlatformAdminGuard],
        loadChildren: () => import('./datasets/datasets.module').then(m => m.DatasetsModule),
      },
      {
        path: 'policies',
        canActivate: [PlatformAdminGuard],
        loadChildren: () => import('./catalog/catalog.module').then(m => m.CatalogModule),
      },
    ],
  },
  {
    path: 'select-organization',
    loadChildren: () => import('./select-organization/select-organization.module').then(m => m.SelectOrganizationModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'register-aws',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule),
  },
  {
    path: '',
    component: SimpleLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      NAVBAR_ROUTE,
      {
        path: 'consent-campaign',
        canActivate: [PlatformAdminGuard, SelectedOrganizationGuard],
        loadChildren: () =>
          import('./consent-broker/consent-campaign-request/consent-campaign-request.module').then(m => m.ConsentCampaignRequestModule),
      },
    ],
  },
  {
    path: 'landings',
    loadChildren: () => import('./landings/landings.routing').then(m => m.LandingsRouterModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
  providers: [{ provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }],
})
export class AppRoutingModule {}
