import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { NotificationComponent } from './notification/notification.component';
import { NotificationCenterComponent } from './notification-center-component';

@NgModule({
  declarations: [NotificationCenterComponent, NotificationComponent],
  imports: [CommonModule, TranslateModule.forRoot()],
  exports: [NotificationCenterComponent, NotificationComponent],
})
export class NotificationCenterModule {}
